@media (min-width: 320px) {
  .second-container {
    display: none;
  }
  .flexRow {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .flex-column {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 768px) {
  .flexRow {
    display: flex;
    flex-direction: row;
    gap: 2rem;
  }
}
@media (min-width: 992px) {
  .second-container {
    display: inline;
  }
}
.highlight {
  font-size: 1rem;
  font-weight: 700;
  color: #B68620;
}

div h3 {
  padding-bottom: 1rem;
  font-weight: bolder;
}

/*div h1 {
  padding-bottom: 1rem;
  font-weight: bolder;
}*/

ol li {
  font-weight: 700;
  padding-bottom: 1rem;
}

ul li {
  font-weight: normal;
  padding-bottom: 0.5rem;
}

p ~ li {
  font-weight: 700;
  padding-bottom: 1rem;
}

.fs-13 {
  font-size: 13px;
}/*# sourceMappingURL=PrivacyPolicy.css.map */