* {
  font-family: 'Poppins';
  font-style: normal;
  margin:  0;
  padding:  0;
  box-sizing:  border-box;
}
@media (min-width: 320px) { 
  .contact-us {
/*  background: rgba(217, 217, 217, 0.25);*/
  width: 100vw;
  }
  .links{
  padding-top: 10px;
  padding-bottom: 10px
  }
  .company-logo{
  /* width: 130px; */
  padding-left: 10px;
  width: 150px;
  }
  .goingUp-img{
  display: none;
/*  overflow: hidden;*/
  }
  .errorImg{
  display: none;
  }
  .going-up{
  display: flex;
  justify-items: center;
  align-items: center;
  background-image: linear-gradient(91deg, #080548 0%, rgba(8, 5, 72, 0) 320%);
  }
  .BannerText{
  animation: fadeIn 3s ease-in;  
  font-size: 25px;
  line-height: 40px;
  }
  .WhoWeAre{
  font-size: 15px;
  line-height: 24px; 
  }
  .ServicesCards{
  margin: 10% 0% 10% 30%;
  }
  .Services-title{
  font-size: 15px;
  }
  .cardParagraph{
    font-size: 14px;
  }
  .about-us-div{
    flex-direction: column;
  }
  .about-us-pic{
    flex-direction: column;
  }
  .letsDoThis-div{
    flex-direction: column;
  }
  .letsDoThis-pic{
    width:100vw;
    max-height: 60vh;
  }
  .div-paragraph{
    font-size: 15px;
  }
  .aboutUs{
    font-size: 30px;
  }
  .footerText{
    font-size: 0.5rem;
  }
  .modal {
  width: 100% !important;
  }
  .letsDoThis-title{
    margin: 0px 0px 70px 0px;
    color: #ffffff;
    font-size: 32px;
    font-weight: 700;
    padding-top: 32px;
  }
  .div-paragraph{
    align-self: flex-end;
    color: #080548;
    font-family: Poppins;
    line-height: 30px;
  }
  .aboutUs{
    color: #080548;
    font-weight: 700;
    line-height: 50px;
  }
}

@media (min-width: 375px) { 
  .contact-us {
 /* background: rgba(217, 217, 217, 0.25);*/
  width: 100vw;
  }
  .custom-padding{
  padding: 1.5rem;
  }
}


@media (min-width: 576px) { 
  .contact-us {
/*  background: rgba(217, 217, 217, 0.25);*/
  width: 50vw;
  margin-left: 25vw;
  }
/*  .button {
  background-color: #F8931F;
  width: 8vw;
  }*/
  .custom-padding{
  padding: 3rem;
  }
    .company-logo{
  /* width: 130px; */
  padding-left: 10px;
  width: 150pxpx;
  }
  .going-up{
  display: flex;
  }
  .BannerText{
  font-size: 20px;
  }
  .WhoWeAre{
  font-size: 20px;
  line-height: 40px; 
  }
  .about-us-pic{
    flex-direction: row;
  }
    .letsDoThis-pic{
    width:100vw;
    max-height: 70vh; 
  }
  .div-paragraph{
    font-size: 20px;
  }
  .aboutUs{
    font-size: 40px;
  }
  .letsDoThis-title{
    margin: 0px 0px 70px 0px;
    color: #ffffff;
    font-size: 32px;
    font-weight: 700;
    padding-top: 0px;
  }
  .div-paragraph{
    align-self: flex-end;
    color: #080548;
    font-family: Poppins;
    line-height: 40px;
  }
  .aboutUs{
    color: #080548;
    font-weight: 700;
    line-height: 80px;
  }
}


@media (min-width: 768px) { 
  .contact-us {
/*  background: rgba(217, 217, 217, 0.25);*/
  width: 90vw;
  margin-left: 5vw;
  }
  .custom-padding{
    padding: 3rem;
  }
  .company-logo{
  padding-left: 10px;
  width: 150pxpx;
  }
  .BannerText{
  font-size: 25px;
  }
  .goingUp-img{
  display: inline;    
  width: 100vw;
  max-height: 75vh;
  }
  .errorImg{
  display: inline;
  width: 50vw;
  max-height: 100vh;
  }
  .WhoWeAre{
  font-size: 30px;
  line-height: 60px; 
  }
  .ServicesCards{
  margin: 10% 30%;
  }
  .letsDoThis-div{
    flex-direction: row;
  }
    .letsDoThis-pic{
    width:60vw;
    max-height: 100vh;  
  }
  .letsDoThis-title{
      margin: 0px 0px 70px 0px;
      color: #ffffff;
      font-size: 40px;
      font-weight: 700;
      padding-top: 0px;
    }
/*    .footerText{
    font-size: 10px;
  }*/
}
  

@media (min-width: 992px) { 
  .contact-us {
/*  background: rgba(217, 217, 217, 0.25);*/
  width: 50vw;
  margin-left: 25vw;
  }
  .links{
  padding-top: 30px;
  padding-bottom: 30px
  }
  .company-logo {
  padding-left: 10px;
  width: 200px;
  }
  .BannerText{
  font-size: 40px;
  line-height: 60px;
  }
  .goingUp-img{
  display: inline;    
  width: 150vw;
  max-height: 100vh;
  }
  .going-up{
  background-image: linear-gradient(91deg, #080548 0%, rgba(8, 5, 72, 0) 60%);
  }
  .Services-title{
  min-height: 42px;
  }
  .about-us-pic{
    flex-direction: column;
  }
  .about-us-div{
    flex-direction: row;
  }
  .modal {
  width: 55vw !important;
  }
}


@media (min-width: 1200px) { 
  .contact-us {
/*  background: rgba(217, 217, 217, 0.25);*/
  width: 50vw;
  margin-left: 25vw;
  }
}

@media (min-width: 1400px) { 
  .Services-title{
  min-height: 0px;
  }
}

/* NavBar CSS */

.links:hover {
  color: #b68620;
}




/* Banner CSS */

.BannerText{
  padding: 30% 10% 0%;   
  color: #ffffff;
  font-weight: 700;
  font-family: Poppins,;
}      

/* Global CSS */

.Highlight{
  display: contents;
  color: #b68620;
  font-weight: 700;
  font-family: Poppins;
}

.BannerButton{
  padding: 15px !important;
  margin: 10%;
  width: 170px;
  background-color: rgb(247, 173, 87, 0) !important;
  border: 5px solid !important; 
  border-color: #b68620 !important;
  box-shadow: none !important;
  font-family: Inter !important;
  white-space: nowrap !important;
  border-radius: 0px !important;
}

.BannerButton:hover{
  background-color: #b68620 !important;
}


.form-control:focus {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 0, 0, 0.6);
}

.midnightBlue{
background-color: #080548;
}

.cardTitle{
  margin: 0px 0px 16px 0px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  font-family: Poppins;
  line-height: 10px;
  white-space: nowrap;
}

.cardParagraph{
  color: #ffffff;
  font-family: Poppins;
  line-height: 20px;
  text-align: center;
}


.WhoWeAre{
  color: #080548; 
  font-family: Poppins; 
  white-space: pre-wrap; 
  text-align: center;
}

.WhoWeAre-container{
  padding: 10% 10% 10% 10%
}


.ServicesCards {
  width: 100px;
  height: 100px; 
  background-color: #B68620; 
  border-style: rounded; 
  border-radius: 50px; 
  padding: 10% 6%;
}

.Services-title{
  margin: 0px 0px 16px 0px; 
  color: #ffffff; 
  font-weight: 700; 
  font-family: Poppins; 
}

.about-us-div{
  gap: 45px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0px 0px 82px 0px;
  padding: 0px 12% 0px 12%;
}

.about-us-pic{
  gap: 66px;
  display: flex;
  justify-content: flex-start;
  padding: 36px 0px;
}

.about-us-ceo{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: flex-start;
  align-items: center;
}


.about-us-cfo{
  height: 285px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.ceo{
/*  width: 154px;
  height: 154px;*/
  align-self: flex-end;
  margin: 0px 40px 11px 0px;  
}

.signature{
  width: 240px;
  height: 84px;
  margin: 0px 0px 3px 0px;
}

.signature-nico{
  width: 240px;
  height: 84px;
  margin: 0px 0px 3px 0px;
}

.defaultSignature{
  width: 249px;
  font-size: 25px;
  line-height: 40px;
  text-align: center;
}

.about-us-title{
  height: 30px;
  font-size: 25px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
}

.yourSuccess{
  display: contents;
  color: #080548;
  font-weight: 700;
  font-family: Poppins;
  line-height: 40px;
  white-space: nowrap;
}

.aboutUs-text{
  gap: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: #080548;
  font-size: 20px;
  font-family: Poppins;
  line-height: 40px;
}

.letsDoThis-div{
  display: flex;
  align-items: center;
  gap: 5%;
  background-color: #080548;
}

.letsDoThis-pic{
  display: inline;   
}


.letsDoThis-text{
  display: flex;
  flex-direction: column;
  place-items: center;
  flex-grow: 1;
}

.letsDoThis-subTitle{
/*  position: absolute;*/
  color: #b68620;
  font-size: 16px;
  font-weight: 700;
  font-family: Poppins;
  line-height: 20px;
}

.letsDoThis-info{
  width: 216px;
  height: 33px;
  text-decoration: none;
  margin: 0px 0px 22px 11px;
  color: #ffffff;
  font-size: 16px;
  font-family: Poppins;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
}

.letsDoThis-info:hover{
  color: #b68620;
}

.ourServices{
  color: #ffffff; 
  font-weight: 700; 
  line-height: 70px;
  text-align: center;
}


/* Transitions */

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.9;
  }
}

.wrapper-class{
  display: flex;
  flex-direction: column-reverse;
  border-width: 2px;
  border-radius: 8px;
  border-style: solid;
  border-color: #000000;
  background-color: #ffffff;
}
.editor-class{
  padding-left: 5px;
  min-height: 150px;
}

.toolbar-class{
column-gap: 30px;  
border: none !important;
}

.button1:hover{
 background-color: red;
}

.SubmitButton{
  height:40px;
  width: 138px;
  margin: 3rem; 
  background-color: #F8931F !important;
  font-family: Inter !important;
  white-space: nowrap !important;
}

.SubmitButton:hover{
  background-color: rgb(247, 173, 87) !important;
}

.footerText{
  color: #FFFFFF;
  font-weight: 700;
  font-family: Poppins;
  line-height: 40px;
  padding: 10px;
  height: 1vh;
}

.footerDiv{
  display: flex;
  padding-bottom: 20px;
  flex-direction: column;
  background-color: #080548
}



.ql-snow  {
  border: none !important;
  shadow: none !important;
  outline: none !important;
}



.modal {
  position: fixed; 
}

.modal-content {
  top: 35vmin;
  margin: 20% auto ;
  border: 1px solid #FFFFFF;
  border-radius: 0px !important;
  width: 100% !important;
  background-color: E5E5E5;
}

.modal-body {
  border: none !important;
  padding-bottom: 0px !important;
}

.modal-footer {
  border: none !important;
  padding-top: 0px !important;
}

.btn-cookies {
  background-color: #B68620!important;
  border-color: #B68620!important;
  box-shadow: none !important;
}

.btn-cookies2 {
  background-color: #C4391D !important;
  border-color: #B68620!important;
  box-shadow: none !important;
}

.form-check-label {
  font-size: 14px;
}

.pb-0{
  padding-bottom: 0px !important;
  margin-bottom: 0px !important ;
}

.navbar-toggler{
  margin-right: 10px !important;
}