
@media (min-width: 320px) { 
    .company-logo{
        width: 130px;
    }
}

@media (min-width: 375px) { 

}


@media (min-width: 576px) { 

}


@media (min-width: 768px) { 
    .company-logo{
        width: 200px;
    }
}
  

@media (min-width: 992px) { 

}


@media (min-width: 1200px) { 

}